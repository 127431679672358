<template>
  <div class="d-flex justify-center align-center mb-4">
    <img src="@/assets/images/logos/logo.png" class="logo" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
  .logo {
    max-height: 65px;
    vertical-align: middle;
  }
</style>